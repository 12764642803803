import { getImage } from "gatsby-plugin-image"

import Seo from "../../components/Seo"
import { Article, Section } from "../../components/containers"
import { ArticleTitle } from "../../components/headings"
import { BookOl, ChapterOl, TocUl } from "../../components/lists"
import { ListLink } from "../../components/links"

import { useCardImage } from "../../hooks/use-card-image"
import { useProsePosts } from "../../hooks/use-prose-posts"

const StoryIndex = ({ location }) => {
  const { largeCard } = useCardImage()
  const seoCard = getImage(largeCard)
  const pageTitle = "Table of Contents"
  const { volOne } = useProsePosts()

  return (
    <>
      <Seo
        pageUrl={location.pathname}
        pageTitle={pageTitle}
        pageDescription={"the world is a beast with a beautiful song"}
        image={seoCard}
      />
      <Article>
        <ArticleTitle title={pageTitle} />
        <Section semantic={false} padding={true}>
          <TocUl>
            <ListLink link="/story/content-notice/" label="Content Notice" />
          </TocUl>
          <BookOl>
            <ChapterOl volume="sparrows" chapters={volOne} />
          </BookOl>
        </Section>
      </Article>
    </>
  )
}

export default StoryIndex
